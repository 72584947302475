import { PureAbility } from '@casl/ability';
import type {actions, domains}  from '@/models/rbac'

export default new PureAbility<[actions, domains]>([
  {action: 'create', subject: 'users/internal'},
  {action: 'read', subject: 'users/internal'},
  {action: 'update', subject: 'users/internal'},
  {action: 'delete', subject: 'users/internal'},
  
  {action: 'create', subject: 'users/client'},
  {action: 'read', subject: 'users/client'},
  {action: 'update', subject: 'users/client'},
  {action: 'delete', subject: 'users/client'},
    
  {action: 'create', subject: 'company'},
  {action: 'read', subject: 'company'},
  {action: 'update', subject: 'company'},
  {action: 'delete', subject: 'company'},

  {action: 'create', subject: 'company/location'},
  {action: 'read', subject: 'company/location'},
  {action: 'update', subject: 'company/location'},
  {action: 'delete', subject: 'company/location'},

  {action: 'create', subject: 'company/location/contact'},
  {action: 'read', subject: 'company/location/contact'},
  {action: 'update', subject: 'company/location/contact'},
  {action: 'delete', subject: 'company/location/contact'},

  {action: 'create', subject: 'company/location/seed'},
  {action: 'read', subject: 'company/location/seed'},
  {action: 'update', subject: 'company/location/seed'},
  {action: 'delete', subject: 'company/location/seed'},
  
  {action: 'create', subject: 'company/users'},
  {action: 'read', subject: 'company/users'},
  {action: 'update', subject: 'company/users'},
  {action: 'delete', subject: 'company/users'},

  {action: 'create', subject: 'company/assets'},
  {action: 'read', subject: 'company/assets'},
  {action: 'update', subject: 'company/assets'},
  {action: 'delete', subject: 'company/assets'},

  {action: 'create', subject: 'company/list'},
  {action: 'read', subject: 'company/list'},
  {action: 'update', subject: 'company/list'},
  {action: 'delete', subject: 'company/list'},

  {action: 'create', subject: 'company/campaign'},
  {action: 'read', subject: 'company/campaign'},
  {action: 'update', subject: 'company/campaign'},
  {action: 'delete', subject: 'company/campaign'},

  {action: 'create', subject: 'company/campaign/templateVersion'},
  {action: 'read', subject: 'company/campaign/templateVersion'},
  {action: 'update', subject: 'company/campaign/templateVersion'},
  {action: 'delete', subject: 'company/campaign/templateVersion'},

  {action: 'create', subject: 'company/campaign/type'},
  {action: 'read', subject: 'company/campaign/type'},
  {action: 'update', subject: 'company/campaign/type'},
  {action: 'delete', subject: 'company/campaign/type'},

  // {action: 'create', subject: 'company/campaign/proof'},
  {action: 'read', subject: 'company/campaign/proof'},
  {action: 'update', subject: 'company/campaign/proof'},
  // {action: 'delete', subject: 'company/campaign/proof'},

  // {action: 'create', subject: 'company/payment/method'},
  // {action: 'read', subject: 'company/payment/method'},
  // {action: 'update', subject: 'company/payment/method'},
  // {action: 'delete', subject: 'company/payment/method'},

  // {action: 'create', subject: 'company/payment/history'},
  {action: 'read', subject: 'company/payment/history'},
  // {action: 'update', subject: 'company/payment/history'},
  // {action: 'delete', subject: 'company/payment/history'},

  {action: 'create', subject: 'company/payment/invoice'},
  {action: 'read', subject: 'company/payment/invoice'},
  // {action: 'update', subject: 'company/payment/invoice'},
  // {action: 'delete', subject: 'company/payment/invoice'},

  // {action: 'create', subject: 'company/orders'},
  {action: 'read', subject: 'company/orders'},
  // {action: 'update', subject: 'company/orders'},
  // {action: 'delete', subject: 'company/orders'},

  {action: 'create', subject: 'brand'},
  {action: 'read', subject: 'brand'},
  {action: 'update', subject: 'brand'},
  {action: 'delete', subject: 'brand'},

  {action: 'create', subject: 'products'},
  {action: 'read', subject: 'products'},
  {action: 'update', subject: 'products'},
  {action: 'delete', subject: 'products'},

  {action: 'create', subject: 'templates'},
  {action: 'read', subject: 'templates'},
  {action: 'update', subject: 'templates'},
  {action: 'delete', subject: 'templates'},

  {action: 'create', subject: 'discounts'},
  {action: 'read', subject: 'discounts'},
  {action: 'update', subject: 'discounts'},
  {action: 'delete', subject: 'discounts'},

  {action: 'create', subject: 'discounts/company'},
  {action: 'read', subject: 'discounts/company'},
  {action: 'update', subject: 'discounts/company'},
  {action: 'delete', subject: 'discounts/company'},

  {action: 'create', subject: 'discounts/accountCredits'},
  {action: 'read', subject: 'discounts/accountCredits'},
  {action: 'update', subject: 'discounts/accountCredits'},
  {action: 'delete', subject: 'discounts/accountCredits'},

  // {action: 'create', subject: 'production/jobs'},
  {action: 'read', subject: 'production/jobs'},
  {action: 'update', subject: 'production/jobs'},
  // {action: 'delete', subject: 'production/jobs'},

  // {action: 'create', subject: 'reports/accounting'},
  {action: 'read', subject: 'reports/accounting'},
  // {action: 'update', subject: 'reports/accounting'},
  // {action: 'delete', subject: 'reports/accounting'},

  // {action: 'create', subject: 'reports/pricing'},
  {action: 'read', subject: 'reports/pricing'},
  // {action: 'update', subject: 'reports/pricing'},
  // {action: 'delete', subject: 'reports/pricing'},

  // {action: 'create', subject: 'reports/discounts'},
  {action: 'read', subject: 'reports/discounts'},
  // {action: 'update', subject: 'reports/discounts'},
  // {action: 'delete', subject: 'reports/discounts'},

  // {action: 'create', subject: 'reports/operations'},
  {action: 'read', subject: 'reports/operations'},
  // {action: 'update', subject: 'reports/operations'},
  // {action: 'delete', subject: 'reports/operations'},

  // {action: 'create', subject: 'campaign/type'},
  {action: 'read', subject: 'campaign/type'},
  {action: 'update', subject: 'campaign/type'},
  {action: 'delete', subject: 'campaign/type'},

  // {action: 'create', subject: 'campaign/support'},
  {action: 'read', subject: 'campaign/support'},
  // {action: 'update', subject: 'campaign/support'},
  // {action: 'delete', subject: 'campaign/support'},

  {action: 'create', subject: 'accounting/invoices'},
  {action: 'read', subject: 'accounting/invoices'},
  {action: 'update', subject: 'accounting/invoices'},
  {action: 'delete', subject: 'accounting/invoices'},
]);
